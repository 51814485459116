import React from 'react'
import './Home.css';
import Layout from '../Layout/Layout'
import { useTranslation } from 'react-i18next';
import profilePic from '../../assets/images/pic5.jpg'

const Home = () => {
    const { t } = useTranslation();
    return (
        <div className='Home'>
            <Layout>
                <div className='intro'>
                    <img src={profilePic} className='Profile-Pic' alt='profilePic'/>
                    <h2>{t('hello_name', 'HELLO! MY NAME IS:')}</h2>
                    <h1>LYNDA CHIWETELU</h1>
                    <h3>{t('full_stack', 'FULLSTACK DEVELOPER, LOVES BACKEND')}</h3>
                    <a href='https://github.com/lyndachiwetelu' target='_blank' rel="noreferrer">{t('view_github')}</a>
                </div>   
            </Layout>  
        </div> 
        
    )
}

export default Home